import React from "react"
import LayoutChild from "../components/layoutChild"
import SEO from "../components/seo"
import { color, fontSize, space } from '../components/constants'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Img from "gatsby-image";


const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 0px 36px 72px;

    @media (max-width: 1024px){
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }

    @media (max-width: 640px){
        grid-template-columns: 100% ;
        ${'' /* grid-template-rows: 100%; */}
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }
`
/* Text Group */
const CellTitleGroup = styled.div`
    width: 75%;
    @media (max-width: 640px){
        ${'' /* grid-row: 2; */}
        width: 75%;
        margin-top: 8px;
    }

`
const Title = styled.div`
    color: white;
    font-size: ${fontSize[3]};
    font-weight: 200;
    margin-top: ${space[4]+ "px"};
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    text-rendering: optimizeLegibility;

    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }

`
const Text = styled.p`
    margin-top: ${space[2]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: ${color.gray2};
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
const Subhead = styled.p`
    margin-top: ${space[4]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: white;
    margin-bottom: 0px;
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
/* Image styles */

const ImgSingle = styled(Img)`
    border-radius: 24px;
`
const ImgDouble = styled(Img)`
    border-radius: 24px;
    grid-column: 1 / span 2;
    @media (max-width: 640px){
        grid-column: 1 / span 1;
    }
`

export default ({ data }) => (
  <LayoutChild>
    <SEO 
      title="Dat One"
      description="An dock-to-dock solution for small carriers"
      image="../images/card.jpg"
    />
    <PageLayout>
        <CellTitleGroup>
            <Title >dat one for&nbsp;mobile</Title>
            <Subhead>ROLE: design director</Subhead>
            <Text>DAT is an industry leader in on-demand freight exchange services, rate benchmarking and forecasting. <br/> DAT One was a major strategic initiative to take three existing application-specific mobile apps and combine them into a single unified driver experience for small carriers.<br/>The result is a successful new platform to integrate, grow and expand DAT’s services and&nbsp;features.</Text>
            <Subhead>design management, strategy, concept, design, and&nbsp;testing.</Subhead>
            </CellTitleGroup>
            <ImgSingle 
                fluid={data.imageOne.childImageSharp.fluid}
                alt="Mobile phone with search application showing"
                backgroundColor="#F6F7F9"
                fadeIn={false}
                 />
            <ImgDouble 
                fluid={data.imageTwo.childImageSharp.fluid}
                backgroundColor="#0046E0"
                alt="Mobile carrier screens"
                fadeIn={false}
                 />
            <ImgDouble
                fluid={data.imageThree.childImageSharp.fluid}
                alt="Mobile search location screens"
                backgroundColor="#E5E5E5"
                fadeIn={false}
                 />
            <ImgDouble 
                fluid={data.imageFour.childImageSharp.fluid}
                alt="Mobile turn-by-turn screens"
                backgroundColor="#3B4148"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageFive.childImageSharp.fluid}
                alt="Mobile phone in car"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageSix.childImageSharp.fluid}
                alt="Mobile phone with app store showing"
                fadeIn={true}
                 />
        </PageLayout>
  </LayoutChild>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "dat-one/dat-1.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageTwo: file(relativePath: { eq: "dat-one/dat-2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440, background: "#ff211a") {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageThree: file(relativePath: { eq: "dat-one/dat-3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFour: file(relativePath: { eq: "dat-one/dat-4.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFive: file(relativePath: { eq: "dat-one/dat-5.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSix: file(relativePath: { eq: "dat-one/dat-6.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`